import styled from 'styled-components';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Container } from '@/components/flexboxgrid';

import InnerSection from '@/components/homepage/header/inner-section';
import withMedia from '@/utils/withMedia';
import theme from '@/utils/styles-variables';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

import BigPicture from '../../../public/assets/images/header/big-header-image.png';
import MediumPicture from '../../../public/assets/images/header/header-image.png';
import MobilePicture from '../../../public/assets/images/header/header-mobile.png';

const Header = ({ content, matches: size }) => {
  const router = useRouter();
  const { ctaButton, subtitle, title } = content;

  const buttonClick = () => {
    trackButtonClicked({
      action: ACTIONS.DEMO_REQUEST,
      buttonText: ctaButton,
      buttonLocation: 'header',
      buttonType: BUTTON_TYPES.NAVIGATION,
      url: router.pathname,
      pageTitle: 'homepage',
    });
  };

  return (
    <Wrapper>
      {size.mobile ? (
        <MobileWrapper>
          <div className="inner-wrapper">
            <InnerSection
              subtitle={subtitle}
              buttonLabel={ctaButton}
              title={title}
              onClick={buttonClick}
            />
          </div>
          <div className="image-wrapper">
            <Image src={MobilePicture} alt="" fill sizes="100vw" />
          </div>
        </MobileWrapper>
      ) : (
        <HeaderContainer>
          <InnerSection
            subtitle={subtitle}
            buttonLabel={ctaButton}
            title={title}
            onClick={buttonClick}
          />
          <StyledImage src={size.medium ? MediumPicture : BigPicture} fill alt="" sizes="70vw" />
        </HeaderContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #ffcaaa;
  position: relative;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .inner-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  }

  .image-wrapper {
    position: relative;
    min-height: 420px;
  }

  img {
    object-fit: cover;
  }
`;

const HeaderContainer = styled(Container)`
  display: flex;
  min-height: 748px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    min-height: 500px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm - 1}px) {
    min-height: 470px;
  }

  img {
    object-fit: cover;

    @media (min-width: 1900px) {
      object-fit: contain;
      object-position: bottom;
    }
  }
`;

const StyledImage = styled(Image)`
  inset: 0 auto !important;
  object-fit: cover;
  right: -225px !important;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    right: -150px !important;
  }

  @media (min-width: 1900px) {
    right: -300px !important;
  }
`;

export default withMedia(Header)({
  queries: {
    big: `(min-width: 1900px)`,
    medium: `(max-width: ${theme.breakpoints.max_md}px)`,
    mobile: `(max-width: ${theme.breakpoints.max_xs}px)`,
  },
  defaultMatches: {
    big: false,
    medium: false,
    mobile: false,
  },
});
