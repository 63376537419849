import Image from 'next/image';
import styled, { css } from 'styled-components';

import IconContainer from '@/components/imageWithList/icon-container';

import MobileShape from '../../../public/assets/icons/homepage/mobile-shape.svg';

import theme from '@/utils/styles-variables';
import withMedia from '@/utils/withMedia';
import getImgSrcWorkaround from '@/utils/images';
import { OUR_SOLUTION_ANIMATION_TIME as ANIMATION_TIME } from '@/utils';

function Solution({ className, data, active, onClick, matches: isMobile }) {
  const { description, icon, image, title } = data;
  return (
    <Wrapper className={className} data-testid="solution">
      <h5>
        <Button onClick={onClick} active={active} type="button">
          <Title data-testid="title">{title}</Title>
          <LinesWrapper>
            <Line active={active} />
            <Line active={active} horizontal />
          </LinesWrapper>
        </Button>
      </h5>
      <Content $active={active} data-testid="panel">
        <Description data-testid="description">{description}</Description>
        {isMobile && active && (
          <ImageWrapper imageHeight={image.data.attributes.height}>
            <StyledShape />
            <WrapperBorder>
              <Image
                src={getImgSrcWorkaround(image.data.attributes.url)}
                alt={image.data.attributes.title}
                fill
                sizes="200px"
                data-testid="mobile-image"
              />
            </WrapperBorder>
            {icon?.data?.attributes?.url && (
              <IconContainer
                src={getImgSrcWorkaround(icon?.data.attributes.url)}
                height={icon?.data.attributes.height}
                width={icon?.data.attributes.width}
                alt={icon?.data.attributes.title}
                homepage
                active={active}
              />
            )}
          </ImageWrapper>
        )}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-top: 1px solid #525252;
  height: 100%;

  &:last-child {
    border-bottom: 1px solid #525252;
  }

  padding-top: 34px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Title = styled.span`
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
`;

const Content = styled.div.attrs((props) => ({
  style: {
    maxHeight: props.$active ? undefined : '0',
    marginBottom: props.$active ? '34px' : '14px',
  },
}))`
  margin-top: 20px;
  max-height: 180px;
  padding-right: 61px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    padding-right: 2px;
    max-height: 240px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-height: 590px;
  }

  transition:
    max-height ${ANIMATION_TIME}ms ease-out,
    margin-bottom ${ANIMATION_TIME}ms ease-out;
`;

const StyledShape = styled(MobileShape)`
  position: absolute;
  object-fit: contain;
  height: auto;
  left: 50%;
  transform: translate(-50%, 0);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    top: -15px;
    max-width: 100%;
  }
`;

const Description = styled.p`
  opacity: 0.9;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
`;

const Button = styled.button.attrs((props) => ({
  style: {
    cursor: props.active ? 'auto' : 'pointer',
  },
}))`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LinesWrapper = styled.span`
  display: block;
  position: relative;
  width: 20px;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  max-height: 300px;
  margin-top: 20px;
  height: 290px;
`;

const WrapperBorder = styled.div`
  position: relative;
  display: block;
  opacity: 1;
  border: 3px solid ${(props) => props.theme.colors.brown};
  border-radius: 23px;
  overflow: hidden;
  width: 118px;
  height: 245px;
  margin: 0 auto;

  img {
    object-fit: cover;
  }
`;

const Line = styled.span.attrs((props) => ({
  style: {
    transform: props.horizontal ? 'rotate(-90deg)' : 'none',
    opacity: props.active ? '0' : '1',
  },
}))`
  position: absolute;
  right: 0;
  width: 20px;
  opacity: 1;
  border-top: 2px solid #000000;

  transition: opacity 300ms ease-in-out;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    transition: none;
    ${(props) =>
      props.active &&
      css`
        opacity: 0;
      `}
  }
`;

export default withMedia(Solution)({
  query: `(max-width: ${theme.breakpoints.max_sm}px)`,
});
