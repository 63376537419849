import styled from 'styled-components';

import { Container, Row, Col } from '@/components/flexboxgrid';
import UnderLineTitle from '@/components/underline-title';

const TrustedByContainer = styled(Container)`
  margin-bottom: 60px;
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    margin-bottom: 120px;
  }
`;

const TitleWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    max-width: 280px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    text-align: center;
  }
`;

const List = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 45px;
    justify-content: center;
  }
`;
const ImageWrapper = styled.div`
  height: 58px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 37px;
    margin: 5px 10px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Item = ({ src, name }) => (
  <div>
    <ImageWrapper>
      <Image src={src} alt={name} />
    </ImageWrapper>
  </div>
);

const TrustedBy = ({ content }) => {
  const { title, list } = content;

  if (!list || !list.length) return null;

  return (
    <TrustedByContainer>
      <Row>
        <Col xs={12} md={5} lg={4}>
          <TitleWrapper>
            <UnderLineTitle smaller variant title={title} />
          </TitleWrapper>
        </Col>
        <Col xs={12} md={7} lg={8}>
          <List>
            {list.map((item, index) => (
              <Item key={index} src={item.image.data.attributes.url} name={item.title} />
            ))}
          </List>
        </Col>
      </Row>
    </TrustedByContainer>
  );
};

export default TrustedBy;
