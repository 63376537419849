import { RemoveScroll } from 'react-remove-scroll';
import ReactModal from 'react-modal';

const defaultStyle = {
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    zIndex: '100000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};

export default function Modal({ children, style, isOpen, onRequestClose, ...props }) {
  return (
    <RemoveScroll enabled={isOpen} removeScrollBar={false}>
      <ReactModal
        closeTimeoutMS={200}
        style={style || defaultStyle}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        {...props}
      >
        {children}
      </ReactModal>
    </RemoveScroll>
  );
}
