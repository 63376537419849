import Media from 'react-media';

const withMedia =
  (Component) =>
  ({ query, queries, defaultMatches }) => {
    const WC = (props) => (
      <Media query={query} queries={queries} defaultMatches={defaultMatches || false}>
        {(matches) => <Component matches={matches} {...props} />}
      </Media>
    );
    WC.displayName = `${Component.displayName || Component.name}WithMedia`;
    return WC;
  };

export default withMedia;
