import { useContext, useEffect, useState } from 'react';
import Media from 'react-media';
import Carousel from 'nuka-carousel';
import styled, { css } from 'styled-components';
import { UAContext } from '@quentin-sommer/react-useragent';

import { Container } from '@/components/flexboxgrid';

import useLottie from '@/utils/useLottie';
import theme from '@/utils/styles-variables';
import Card from './card';

import animationData from '../../../public/assets/animations/testimonial.json';

const StyledCard = styled(Card)``;

const StyledContainer = styled(Container)`
  padding: 0;
  max-width: 980px;
`;

const ConditionsWeCoverSlider = styled.div`
  margin-bottom: 100px;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 90px;
    margin-top: 70px;
  }
`;

const Arrow = styled.div`
  background-color: #fcf7f3;
  width: 71px;
  height: 71px;
  border-radius: 50%;
  position: relative;
  transform: translateY(0) rotate(180deg);
  cursor: pointer;
  transition: all 0.3s ease;

  ${(props) =>
    props.next &&
    css`
      transform: rotate(0deg);
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
      &:before {
        pointer-events: none;
      }
    `}
  &:hover {
    background-color: #f7eae1;
  }

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    height: 24px;
    width: 13px;
    background: url('/assets/icons/arrow.svg') no-repeat center center;
    background-size: cover;
    transition: all 0.3s ease;
  }
`;

const Shape = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: -20px;
    height: 120%;
  }
`;

const CarouselWrap = styled.div`
  & .slider-slide {
    visibility: inherit !important;
    opacity: 1 !important;
    ${StyledCard} {
      opacity: 0 !important;
      transform: translateY(20px);
      transition:
        opacity 500ms 0ms ease-in-out,
        transform 500ms 0ms ease-in-out;
    }
  }

  & .slider-slide.slide-current ${StyledCard} {
    opacity: 1 !important;
    transform: translateY(0px) !important;
    transition:
      opacity 500ms 500ms ease-in-out,
      transform 500ms 500ms ease-in-out;
  }

  & .dots-container {
    top: ${(props) => (props.$isDesktop ? '-90px' : '45px')} !important;
  }
`;

const SEGMENTS = [26, 111, 180];

const Testimonials = ({ testimonials }) => {
  const { uaResults } = useContext(UAContext);
  const [animationItem, animationRef] = useLottie(animationData);
  const [segment, setSegment] = useState(0);

  const slideChange = (slider) => {
    if (slider > segment) {
      animateNext();
    } else {
      animatePrevious();
    }
  };

  useEffect(() => {
    if (animationItem) {
      animationItem.setSpeed(1.3);
      animationItem.goToAndStop(26, true);
    }
  }, [animationItem]);

  const animateNext = () => {
    animationItem.playSegments([SEGMENTS[segment], SEGMENTS[segment + 1]], true);
    setSegment((prev) => prev + 1);
  };

  const animatePrevious = () => {
    animationItem.playSegments([SEGMENTS[segment], SEGMENTS[segment - 1]], true);
    setSegment((prev) => prev - 1);
  };

  if (!testimonials || !testimonials.length) return null;

  return (
    <StyledContainer>
      <ConditionsWeCoverSlider>
        <Shape ref={animationRef} />
        <Media
          queries={{ desktop: `(min-width: ${theme.breakpoints.min_lg}px)` }}
          defaultMatches={{ small: uaResults.mobile }}
        >
          {(matches) => (
            <CarouselWrap $isDesktop={matches.desktop}>
              <Carousel
                width="100%"
                slidesToShow={1}
                dragging
                transitionMode="fade"
                disableEdgeSwiping
                afterSlide={slideChange}
                speed={0}
                renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) =>
                  matches.desktop ? (
                    <Arrow disabled={currentSlide === slideCount - 1} onClick={nextSlide} next />
                  ) : null
                }
                renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                  matches.desktop ? (
                    <Arrow disabled={currentSlide <= 0} onClick={previousSlide} />
                  ) : null
                }
                defaultControlsConfig={{
                  pagingDotsContainerClassName: 'dots-container',
                  pagingDotsStyle: {
                    backgroundColor: '#30261D',
                    fill: 'transparent',
                    borderRadius: '50%',
                    height: '9px',
                    width: '9px',
                    margin: !matches.small ? '0 4px' : '0 3px',
                  },
                }}
              >
                {testimonials?.map((testimonial) => (
                  <StyledCard
                    key={testimonial.quote}
                    sentence={testimonial.quote}
                    authorName={testimonial.authorName}
                    authorAge={testimonial.authorAge}
                  />
                ))}
              </Carousel>
            </CarouselWrap>
          )}
        </Media>
      </ConditionsWeCoverSlider>
    </StyledContainer>
  );
};

export default Testimonials;
