import styled from 'styled-components';
import ReactPlayer from 'react-player';

import Modal from '@/components/core/Modal';

const CloseButton = styled.button`
  position: fixed;
  content: '';
  top: 80px;
  right: 80px;
  width: 42px;
  height: 42px;
  z-index: 100001;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    top: 40px;
    right: 40px;
    width: 22px;
    height: 22px;
  }
`;

const Cross = styled.div`
  position: relative;
  &:before,
  &:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 120%;
    background-color: #000;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    left: 0;
    transform: rotate(-45deg);
  }
`;

const Video = styled.div`
  position: relative;
  width: 931px;
  height: calc(931px * 9 / 16);
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 80vw;
    height: calc(80vw * 9 / 16);
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const VideoPlayerModal = ({ modalIsOpen, closeModal, videoURL }) => (
  <>
    {modalIsOpen && (
      <CloseButton type="button" onClick={closeModal} data-testid="close-video">
        <Cross />
      </CloseButton>
    )}
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
      <Video>
        <ReactPlayer
          className="react-player"
          url={videoURL}
          controls
          width="100%"
          height="100%"
          playing
        />
      </Video>
    </Modal>
  </>
);

export default VideoPlayerModal;
