import styled from 'styled-components';

import FadeCard from '@/components/fade-card';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 396px;
  gap: 27px;
`;

function DesktopConditionsWeCover({ list }) {
  return (
    <Wrapper>
      {list.map((condition) => (
        <FadeCard
          key={condition.id}
          title={condition.title}
          description={condition.description}
          image={condition.image}
        />
      ))}
    </Wrapper>
  );
}

export default DesktopConditionsWeCover;
