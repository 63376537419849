import styled from 'styled-components';
import Link from 'next/link';

import ReactMarkdown from 'react-markdown';

import { Container, Row, Col } from '@/components/flexboxgrid';

const Metrics = ({ data }) => {
  if (!data || !data.length) {
    return null;
  }
  return (
    <CustomContainer>
      <Row>
        {data.map((e, index) => (
          <InfoCol data-testid="metrics-item" key={index} lg={4} md={4} xs={12}>
            <Link href="/references">
              <Number>{e.number}</Number>
            </Link>
            <Label>{e.info}</Label>
          </InfoCol>
        ))}
      </Row>
    </CustomContainer>
  );
};

const CustomContainer = styled(Container)`
  padding-top: 65px;
  padding-bottom: 65px;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 68px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 66px;
  }
`;

const Number = styled(ReactMarkdown)`
  display: block;
  font-family: ${(props) => props.theme.fonts.nantes_book};
  font-weight: 500;
  font-size: 30px;
  color: ${(props) => props.theme.colors.peach};
  line-height: 86px;
  margin-bottom: 15px;

  i,
  em {
    font-style: italic;
    font-weight: 600;
  }
  b,
  strong {
    font-size: 45px;
    font-weight: 700;
  }
`;

const InfoCol = styled(Col)`
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    justify-content: space-between;
    & + & {
      padding-top: 30px;
    }
  }

  ${Number} {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  &:nth-child(3n + 1) {
    ${Number} {
      &:before {
        background: url('/assets/images/homepage/metrics/metric1.png') no-repeat center center;
      }
    }
  }
  &:nth-child(3n + 2) {
    ${Number} {
      &:before {
        background: url('/assets/images/homepage/metrics/metric2.png') no-repeat center center;
      }
    }
  }
  &:nth-child(3n + 3) {
    ${Number} {
      &:before {
        background: url('/assets/images/homepage/metrics/metric3.png') no-repeat center center;
      }
    }
  }
`;

const Label = styled(ReactMarkdown)`
  font-family: ${(props) => props.theme.fonts.grosa};
  font-size: 18px;
  color: ${(props) => props.theme.colors.brown};
  line-height: 30px;
  max-width: 270px;
  margin: 0 auto;

  strong {
    font-weight: 700;
  }
`;

export default Metrics;
