import * as Sentry from '@sentry/nextjs';

import Seo from '@/components/shared/SEO';
import withPageBanner from '@/components/core/withPageBanner';
import Header from '@/components/homepage/header/index';
import ProductPresentation from '@/components/homepage/product-presentation';
import Metrics from '@/components/homepage/metrics/index';
import ConditionsWeCover from '@/components/conditions-we-cover';
import Testimonials from '@/components/homepage/testimonials';
import OurClinicalAdvisory from '@/components/homepage/our-clinical-advisory';
import Testimonial from '@/components/homepage/testimonial';
import OurSolution from '@/components/homepage/our-solution';
import TrustedBy from '@/components/homepage/trusted-by';
import CallToAction from '@/components/call-to-action';
import HowItWorks from '@/components/homepage/how-it-works';
import OurClients from '@/components/homepage/our-clients';

import { getHomepage } from '@/services/homepage';
import { getNavigation } from '@/services/navigation';

const Home = ({ content }) => {
  const {
    seo,
    header,
    metrics,
    productPresentation,
    ourSolution,
    conditionsWeCover,
    howItWorks,
    testimonials,
    ourClients,
    ourClinicalAdvisory,
    testimonial,
    trustedBy,
    cta,
  } = content;

  return (
    <div>
      <Seo seo={seo} />
      {header && <Header content={header} />}
      {metrics && <Metrics data={metrics.data} />}
      {productPresentation && <ProductPresentation content={productPresentation} />}
      {ourSolution && <OurSolution content={ourSolution} />}
      {conditionsWeCover && <ConditionsWeCover content={conditionsWeCover} />}
      {howItWorks && <HowItWorks content={howItWorks} />}
      {testimonials && <Testimonials testimonials={testimonials} />}
      {ourClients && <OurClients content={ourClients} />}
      {ourClinicalAdvisory && <OurClinicalAdvisory content={ourClinicalAdvisory} />}
      {testimonial && <Testimonial content={testimonial} />}
      {trustedBy && <TrustedBy content={trustedBy} />}
      {cta && (
        <CallToAction
          title={cta.title}
          buttonLabel={cta.button.buttonLabel}
          buttonLink="/eligibility"
          wide
        />
      )}
    </div>
  );
};

export const getStaticProps = withPageBanner(async () => {
  try {
    const content = await getHomepage();
    const navigation = await getNavigation();

    return {
      props: {
        content,
        navigation,
      },
      revalidate: 60,
    };
  } catch (error) {
    Sentry.captureException(error);

    if (error.response?.status === 404) {
      return {
        notFound: true,
        revalidate: 60,
      };
    }

    throw new Error('Failed to fetch Home data');
  }
});

Home.displayName = 'Homepage';
export default Home;
