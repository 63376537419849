import { NextSeo } from 'next-seo';
import { SEOMetadata } from '@/config/seo-meta';
import { useRouter } from 'next/router';
import getCanonicalPath from '@/utils/get-canonical-path';

export default function Seo({ seo }) {
  const router = useRouter();

  if (!seo) {
    return null;
  }

  return (
    <NextSeo
      title={seo.title}
      description={seo.description}
      canonical={getCanonicalPath(router)}
      openGraph={{
        url: `${SEOMetadata.baseCanonical}${router.asPath}`,
        title: seo.title,
        description: seo.description,
        images: [
          {
            url: seo.image.data ? seo.image.data.attributes.url : seo.image.url,
            width: seo.image.data ? seo.image.data.attributes.width : seo.image.width,
            height: seo.image.data ? seo.image.data.attributes.height : seo.image.height,
            alt: seo.image.data ? seo.image.data.attributes.alternativeText : seo.alternativeText,
            type: seo.image.data ? seo.image.data.attributes.mime : seo.image.mime,
          },
        ],
      }}
      twitter={{
        cardType: 'summary',
      }}
    />
  );
}
