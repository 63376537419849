import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import Slider from 'react-slick';
import styled from 'styled-components';

import { Container } from '@/components/flexboxgrid';
import UnderLineTitle from '@/components/underline-title';

import withMedia from '@/utils/withMedia';
import theme from '@/utils/styles-variables';

const MarqueeSlider = ({ title, board, children, matches: tablet, length }) => {
  const [width, setWidth] = useState(0);

  const sliderSettings = {
    arrows: false,
    centerMode: true,
    centerPadding: `${width}px`,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    rows: 1,
  };

  function getSliderWidth() {
    return (
      (window.innerWidth - (window.innerWidth > 700 ? (board ? 300 : 488) : board ? 290 : 278)) / 2
    );
  }

  useEffect(() => {
    if (width === 0) {
      setWidth(getSliderWidth());
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(getSliderWidth());
    }

    window.addEventListener('load', handleResize, false);
    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('load', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <CustomTitle>
        <UnderLineTitle title={title} centered variant smaller data-testid="marquee-title" />
      </CustomTitle>
      <>
        {tablet ? (
          <TabletWrapper data-testid="slider">
            <Slider {...sliderSettings}>{children}</Slider>
          </TabletWrapper>
        ) : (
          <>
            {length <= 4 ? (
              <>
                <Container data-testid="list">
                  <CarouselWrapper>
                    <Wrapper>{children}</Wrapper>
                  </CarouselWrapper>
                </Container>
              </>
            ) : (
              <CarouselWrapper data-testid="marquee">
                <Marquee pauseOnHover gradient={false} speed={70}>
                  {children}
                </Marquee>
              </CarouselWrapper>
            )}
          </>
        )}
      </>
    </>
  );
};

const TabletWrapper = styled.div`
  margin-top: 112px;
  margin-bottom: 79px;

  .slick-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          :focus {
            outline: none;
          }

          :nth-child(3n + 1) > div > div > div > div {
            background-color: ${(props) => props.theme.colors.light_peach};
          }

          :nth-child(3n + 2) > div > div > div > div {
            background-color: ${(props) => props.theme.colors.peach};
          }

          :nth-child(3n + 3) > div > div > div > div {
            background-color: ${(props) => props.theme.colors.dark_purple};
          }
        }
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 45px;
    margin-bottom: 53px;
  }
`;

const CustomTitle = styled.div`
  display: grid;
  margin: 0 auto;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 284px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  row-gap: 20px;
`;

const CarouselWrapper = styled.div`
  margin-top: 96px;
  margin-bottom: 132px;

  .marquee-container {
    .marquee > div {
      :nth-child(3n + 1) > div > div {
        background-color: ${(props) => props.theme.colors.light_peach};
      }

      :nth-child(3n + 2) > div > div {
        background-color: ${(props) => props.theme.colors.peach};
      }

      :nth-child(3n + 3) > div > div {
        background-color: ${(props) => props.theme.colors.dark_purple};
      }
    }
  }

  .rfm-initial-child-container {
    align-items: flex-start;
  }
`;

export default withMedia(MarqueeSlider)({
  query: `(max-width: ${theme.breakpoints.max_lg}px)`,
});
