import styled from 'styled-components';

import BoardComponent from '@/components/advisory-board/board-component';

import theme from '@/utils/styles-variables';
import withMedia from '@/utils/withMedia';

const OurClinicalAdvisory = ({ content, matches: size }) => {
  const { data, title } = content;
  return (
    <OurClientsWrapper>
      <BoardComponent title={title} data={data} size={size} board />
    </OurClientsWrapper>
  );
};

const OurClientsWrapper = styled.div`
  margin-bottom: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 70px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 50px;
  }
`;

export default withMedia(OurClinicalAdvisory)({
  queries: {
    tablet: `(max-width: ${theme.breakpoints.max_lg}px)`,
    mobile: `(max-width: ${theme.breakpoints.max_sm}px)`,
  },
  defaultMatches: {
    tablet: false,
    mobile: false,
  },
});
