import styled from 'styled-components';

const CardWrapper = styled.div`
  font-family: ${(props) => props.theme.fonts.grosa};
  padding: 0 12px;
  height: 200px;
  display: flex;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    height: 400px;
  }
`;

const CardContent = styled.div`
  width: 620px;
  margin: auto;
  text-align: center;
`;

const Info = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.brown};
`;

const Sentence = styled.p`
  font-size: 22px;
  line-height: 30px;
  max-height: 90px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0 0 11px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    font-size: 33px;
    line-height: 44px;
    max-height: 130px;
  }
`;

const Author = styled.p`
  font-size: 14px;
  line-height: 26px;
  opacity: 0.65;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

const Card = ({ className, sentence, authorName, authorAge }) => (
  <CardWrapper className={className}>
    <CardContent>
      <Info>
        <Sentence>{`"${sentence}"`}</Sentence>
        <Author>{`${authorName}, ${authorAge} years old`}</Author>
      </Info>
    </CardContent>
  </CardWrapper>
);
export default Card;
