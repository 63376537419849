import Image from 'next/image';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { Col, Container, Row } from '@/components/flexboxgrid';

import withMedia from '@/utils/withMedia';
import theme from '@/utils/styles-variables';
import getImgSrcWorkaround from '@/utils/images';

function ProductPresentation({ content, matches: isMobile }) {
  const { ref, inView: animate } = useInView({
    rootMargin: '20px',
    threshold: 0.5,
    triggerOnce: true,
  });

  const { description, products, image } = content;

  return (
    <Wrapper ref={ref}>
      <OuterContainer>
        <InnerContainer>
          <StyledRow>
            <ContentCol>
              <Description>{description}</Description>
              {products?.map((product, index) => (
                <Item key={index}>
                  <ItemImageWrapper>
                    <Image
                      className={`img img${index + 1}`}
                      src={
                        (isMobile &&
                          getImgSrcWorkaround(product.mobileImage?.data.attributes.url)) ||
                        getImgSrcWorkaround(product.image.data.attributes.url)
                      }
                      alt={
                        (isMobile && product.mobileImage?.data.attributes.alternativeText) ||
                        product.image.data.attributes.alternativeText
                      }
                      fill
                      sizes="(max-width: 768px) 100vw, 250px"
                      data-testid={`product-image-${index}`}
                    />
                  </ItemImageWrapper>
                  <InfoWrapper>
                    <ItemTitle>{product.title}</ItemTitle>
                    <ItemDescription>{product.description}</ItemDescription>
                  </InfoWrapper>
                </Item>
              ))}
            </ContentCol>
            <ImageCol>
              <ImageAnimationWrapper>
                <ImageAnimation className={cn({ animate })}>
                  <Image
                    src={getImgSrcWorkaround(image.data.attributes.url)}
                    alt={image.data.attributes.alternativeText}
                    fill
                    sizes="(max-width: 768px) 100vw, 60vw"
                    data-testid="main-image"
                  />
                </ImageAnimation>
              </ImageAnimationWrapper>
            </ImageCol>
          </StyledRow>
        </InnerContainer>
      </OuterContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.light_peach};
    opacity: 0.2;
    z-index: -1;
  }
`;

const OuterContainer = styled(Container)`
  max-width: none !important;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const InnerContainer = styled.div`
  padding-left: max(calc((100% - 1306px) / 2 + 45px), 45px);

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    padding-left: 0;
  }
`;

const StyledRow = styled(Row)`
  flex-flow: row nowrap;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-flow: column nowrap;
    margin-left: -20px;
    margin-right: -20px;
  }
`;

const ContentCol = styled(Col)`
  width: 744px;
  padding-top: 130px;
  padding-bottom: 85px;
  padding-right: 106px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    width: 480px;
    padding-top: 61px;
    padding-bottom: 63px;
    padding-right: 17px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 41px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    padding: 63px 20px 45px 20px;
  }
`;

const ImageCol = styled(Col)`
  position: relative;
  flex: 1;
  margin-right: -29px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    font-size: 26px;
    line-height: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex: none;
    width: 100%;
    height: 301px;
  }
`;

const ImageAnimationWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  margin-left: -16px;
  margin-right: -16px;
`;

const ImageAnimation = styled.div`
  height: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    transform: scale(1);
    &.animate {
      transform: scale(1.13);
      transition: transform 900ms ease-out;
    }
  }

  img {
    object-fit: cover;
  }
`;

const Description = styled.h2`
  font-family: ${(props) => props.theme.fonts.grosa};
  color: ${(props) => props.theme.colors.brown};
  font-size: 40px;
  line-height: 55px;
  font-weight: 300;
  letter-spacing: -0.02em;
  margin-bottom: 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    font-size: 26px;
    line-height: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 15px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 0;
  }
`;

const ItemImageWrapper = styled.div`
  position: relative;
  width: 160px;
  height: 135px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    width: 200px;
    height: 133px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 182px;
    height: 158px;
  }

  img {
    object-fit: contain;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      object-position: left center;
    }
  }
`;

const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-right: 10px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 35px;
    padding-right: 0;
    align-items: flex-start;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    flex-direction: column;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  max-width: 425px;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    max-width: fit-content;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 10px;
    padding-bottom: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    margin-left: 0;
  }
`;

const ItemTitle = styled.h5`
  font-family: ${(props) => props.theme.fonts.grosa};
  color: ${(props) => props.theme.colors.brown};
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 22px;
    line-height: 28px;
    margin-top: 10px;
  }
`;

const ItemDescription = styled.p`
  font-family: ${(props) => props.theme.fonts.grosa};
  color: ${(props) => props.theme.colors.brown};
  font-size: 18px;
  line-height: 30px;
  margin-top: 9px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    font-size: 18px;
    line-height: 30px;
    margin-top: 9px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 18px;
    line-height: 30px;
    margin-top: 10px;
  }
`;

export default withMedia(ProductPresentation)({
  query: `(max-width: ${theme.breakpoints.max_sm}px)`,
});
