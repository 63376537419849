import styled from 'styled-components';

import UnderLineTitle from '@/components/underline-title';
import { PrimaryButton } from '@/components/core/button';

const InnerSection = ({ title, subtitle, buttonLabel, onClick }) => (
  <BigWrapper>
    <Title>
      <UnderLineTitle title={title} header stroke="#FB987A" />
    </Title>
    <SubTitle>{subtitle}</SubTitle>
    <PrimaryButton onClick={onClick} href="/request-demo">
      {buttonLabel}
    </PrimaryButton>
  </BigWrapper>
);

const Title = styled.h1`
  max-width: 600px;
`;
const BigWrapper = styled.div`
  position: relative;
  z-index: 1;
  align-self: center;
  padding-top: 90px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 100px;
    padding-bottom: 0;
  }
`;

const SubTitle = styled.div`
  max-width: 384px;
  font-family: ${(props) => props.theme.fonts.grosa};
  font-size: 18px;
  line-height: 30px;
  color: ${(props) => props.theme.colors.brown};
  padding-top: 45px;
  padding-bottom: 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 30px;
    padding-bottom: 35px;
  }
`;

export default InnerSection;
