import styled from 'styled-components';
import Image from 'next/image';

import MarqueeSlider from '@/components/marquee-slider';

import getImgSrcWorkaround from '@/utils/images';

const BoardComponent = ({ data, title, board }) => {
  if (!data || !data?.length) return null;

  return (
    <MarqueeSlider title={title} board={board} length={data.length}>
      {data.map((item, index) => (
        <DesktopWrapper key={index}>
          <DesktopWrapperInner>
            <ImageWrapper>
              <Image
                alt={item.image.data.attributes.alternativeText}
                src={getImgSrcWorkaround(item.image.data.attributes.url)}
                fill
                sizes="(max-width: 768px) 100vw, 500px"
                data-testid="person-image"
              />
            </ImageWrapper>
            <Name data-testid="person-name">{item.name}</Name>
            <Specialization data-testid="person-specialization">
              {item.specialization}
            </Specialization>
          </DesktopWrapperInner>
        </DesktopWrapper>
      ))}
    </MarqueeSlider>
  );
};

const DesktopWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 1800px) {
    width: 310px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 268px;
    scroll-snap-align: start;
  }
`;

const DesktopWrapperInner = styled.div`
  width: 280px;
  margin: auto;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  background-color: rgba(238, 213, 196, 0.2);

  img {
    object-fit: cover;
  }
`;

const Name = styled.h5`
  font-size: 22px;
  line-height: 28px;
  margin-top: 24px;
  font-weight: 500;
  width: 85%;
`;

const Specialization = styled.p`
  font-size: 18px;
  line-height: 24px;
  margin-top: 9px;
  max-width: 280px;
  width: 85%;
`;

export default BoardComponent;
