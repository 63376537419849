export default function getImgSrcWorkaround(src) {
  try {
    // if image src is a valid url return as is
    new URL(src);

    return src;
  } catch {
    if (process.env.NEXT_PUBLIC_LOCALHOST_IMAGE_DOMAIN) {
      return process.env.NEXT_PUBLIC_LOCALHOST_IMAGE_DOMAIN + src;
    }

    return src;
  }
}
