import { useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import cn from 'classnames';

import theme from '@/utils/styles-variables';
import withMedia from '@/utils/withMedia';
import getImgSrcWorkaround from '@/utils/images';
import { SecondaryButton } from '@/components/core/button';

import CloseSVG from '../public/assets/images/homepage/conditions-we-cover/close.svg';

const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.light_card};
`;

const Display = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 450ms 300ms ease-out;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    ${Wrapper}:hover & {
      transition: opacity 450ms ease-out;
      opacity: 0;
    }
  }
  &.open {
    transition: opacity 450ms ease-out;
    opacity: 0;
  }
`;

const HoverDisplay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 450ms ease-out;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    ${Wrapper}:hover & {
      transition: opacity 450ms 300ms ease-out;
      opacity: 1;
    }
  }
  &.open {
    transition: opacity 450ms 300ms ease-out;
    opacity: 1;
  }
`;

const Title = styled.h5`
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  margin-top: 20px;
  &:first-child {
    margin: 0;
  }
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 15px;
`;

const ImageWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 177px;
    height: 177px;
  }

  img {
    object-fit: contain;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      height: 100%;
      width: 100%;
    }
  }
`;

const OpenButton = styled(SecondaryButton)`
  margin-top: 20px;
  z-index: 1;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  margin: 0;
  padding: 0;
  border: none;
`;

function FadeCard({ className, matches: smallerDevice, title, description, image }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Wrapper className={className}>
      <Display className={cn({ open: smallerDevice && open })}>
        <ImageWrapper>
          <Image
            alt={image.data.attributes.alternativeText}
            src={getImgSrcWorkaround(image.data.attributes.url)}
            width={225}
            height={225}
          />
        </ImageWrapper>
        <Title>{title}</Title>
        {smallerDevice && (
          <OpenButton small onClick={handleOpen}>
            Know more
          </OpenButton>
        )}
      </Display>
      <HoverDisplay className={cn({ open: smallerDevice && open })}>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {smallerDevice && (
          <CloseButton onClick={handleClose}>
            <CloseSVG />
          </CloseButton>
        )}
      </HoverDisplay>
    </Wrapper>
  );
}

export default withMedia(FadeCard)({
  query: `(max-width: ${theme.breakpoints.max_md}px)`,
});
