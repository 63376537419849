import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { useRouter } from 'next/router';

import { Container } from '@/components/flexboxgrid';
import { PrimaryButton } from '@/components/core/button';

import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

export default function CallToAction({ title, buttonLabel, buttonLink, wide = false }) {
  const [position, setPosition] = useState([100, 50]);
  const ref = useRef();
  const router = useRouter();

  if (!title && !buttonLabel && !buttonLink) return null;

  const handleMouseMove = (e) => {
    e.stopPropagation();
    const rect = ref.current.getBoundingClientRect();
    setPosition([
      ((e.clientX - rect.left) / rect.width) * 100,
      ((e.clientY - rect.top) / rect.height / 2) * 100,
    ]);
  };

  const trackEvent = () => {
    let action = null;

    if (buttonLink.startsWith('/request-demo')) {
      action = ACTIONS.DEMO_REQUEST;
    } else if (buttonLink.startsWith('/eligibility')) {
      action = ACTIONS.CLICK_CHECK_ELIGIBILITY;
    }

    trackButtonClicked({
      action: action,
      buttonType: BUTTON_TYPES.NAVIGATION,
      buttonText: buttonLabel,
      buttonLocation: 'CTA',
      destination: buttonLink,
      url: router.pathname,
    });
  };

  return (
    <CTA ref={ref} onMouseMove={handleMouseMove}>
      <AnimatedColor position={position} />
      <Container>
        <Title
          wide={wide}
          components={{
            em: ({ ...props }) => <i {...props}>{props.children}</i>,
          }}
        >
          {title}
        </Title>
        <PrimaryButton data-testid="callAction-button" href={buttonLink} onClick={trackEvent}>
          {buttonLabel}
        </PrimaryButton>
      </Container>
    </CTA>
  );
}

const CTA = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 68px;
    padding-bottom: 68px;
  }
  &:before {
    content: '';
    position: absolute;
    background-color: ${(props) => props.theme.colors.light_peach};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
`;

const AnimatedColor = styled.div.attrs((props) => ({
  style: {
    transform: `translate(${props.position[0]}%, ${props.position[1]}%)`,
  },
}))`
  position: absolute;
  background: radial-gradient(rgba(202, 149, 218, 0.5), transparent 75%);
  top: -100%;
  left: -50vw;
  width: 100vw;
  height: 200%;
  transform: translate(100%, 50%);
  z-index: -1;
  transition: transform 2s cubic-bezier(0.58, 1.32, 0.7, 1.43);
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    transform: translate(100%, 50%) !important;
  }
`;

const Title = styled(ReactMarkdown)`
  margin: auto;
  max-width: 901px;
  font-size: 70px;
  line-height: 90px;
  text-align: center;
  letter-spacing: -0.03em;
  color: ${(props) => props.theme.colors.brown};
  padding-bottom: 40px;
  font-weight: 300;
  i {
    font-family: ${(props) => props.theme.fonts.nantes_book};
    font-weight: 500;
    font-style: italic;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    font-size: 65px;
    line-height: 85px;
    max-width: 500px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 40px;
    line-height: 55px;
    padding-bottom: 34px;
    max-width: 400px;
  }
  ${(props) =>
    props.wide &&
    css`
      max-width: 387px;
      font-size: 100px;
      line-height: 100px;
      @media (max-width: ${(props) => props.theme.breakpoints.min_xl}px) {
        font-size: 85px;
        line-height: 90px;
        max-width: 346px;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        font-size: 46px;
        line-height: 45px;
        padding-bottom: 34px;
        max-width: 200px;
      }
    `}
`;
