import React, { useState } from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';
import Image from 'next/image';

import { Col, Container, Row } from '@/components/flexboxgrid';
import IconContainer from '@/components/imageWithList/icon-container';
import UnderLineTitle from '@/components/underline-title';

import Solution from './solution';

import withMedia from '@/utils/withMedia';
import theme from '@/utils/styles-variables';
import getImgSrcWorkaround from '@/utils/images';
import { OUR_SOLUTION_ANIMATION_TIME as ANIMATION_TIME } from '@/utils';

import Shape from '../../../public/assets/icons/homepage/solution.svg';

function OurSolution({ content, matches: isMobile }) {
  const [active, setActive] = useState(0);

  const handleClick = (index) => {
    if (active !== index) {
      setActive(index);
    }
  };

  const { title, accordion } = content;
  return (
    <Wrapper>
      <StyledContainer>
        <StyledRow>
          <ContentCol xs={12} md={6} lg={5}>
            <UnderLineTitle title={title} oneLine smaller variant data-testid="section-title" />
            <SolutionsWrapper>
              {accordion.map((solution, index) => (
                <Solution
                  key={solution.title}
                  data={solution}
                  active={active === index}
                  onClick={() => handleClick(index)}
                  animationTime={ANIMATION_TIME}
                />
              ))}
            </SolutionsWrapper>
          </ContentCol>
          {!isMobile && (
            <Col md={6} lg={7}>
              <ImageCol>
                <StyledShape />
                <OuterWrapper>
                  {accordion?.map((solution, index) => (
                    <ImageWrapper key={solution.title} className={cn({ active: active === index })}>
                      <PhoneImageWrapper active={active === index}>
                        <Phone />
                        <Image
                          src={getImgSrcWorkaround(solution.image.data.attributes.url)}
                          alt={solution.image.data.attributes.alternativeText}
                          fill
                          sizes="300px"
                          data-testid="desktop-image"
                        />
                      </PhoneImageWrapper>
                      {solution?.icon?.data && (
                        <IconContainer
                          src={getImgSrcWorkaround(solution.icon.data.attributes.url)}
                          alt={solution.icon?.data.attributes.alternativeText}
                          homepage
                          active={active === index}
                        />
                      )}
                    </ImageWrapper>
                  ))}
                </OuterWrapper>
              </ImageCol>
            </Col>
          )}
        </StyledRow>
      </StyledContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 85px;
  margin-bottom: 100px;
  overflow: visible;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 53px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 49px;
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  height: 840px;
  padding-top: 100px;
  overflow: visible;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 760px;
    padding-top: 65px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 1050px;
    padding-top: 46px;
  }
`;

const StyledRow = styled(Row)`
  height: 100%;
`;

const ContentCol = styled(Col)`
  height: 100%;
  padding-left: 74px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-left: 16px;
  }
`;

const SolutionsWrapper = styled.div`
  margin-top: 63px;
`;

const ImageCol = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    margin-top: 50px;
  }
`;

const StyledShape = styled(Shape)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 583.07px;
  height: 456.5px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 519.16px;
    height: 406.46px;
    left: 0;
    transform: translateY(-50%);
  }
`;

const OuterWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  width: 288px;
  height: 602px;
  margin: 0 auto;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 197px;
    height: 412px;
    border-radius: 31px;
  }
`;

const PhoneImageWrapper = styled.div`
  border-radius: 32px;
  height: 100%;
  overflow: hidden;
  position: relative;
  opacity: 0;
  transition:
    transform ${ANIMATION_TIME}ms ease-in-out,
    opacity ${ANIMATION_TIME}ms ease-in-out;
  z-index: 10;

  ${(props) =>
    props.active &&
    css`
      transition-delay: ${ANIMATION_TIME / 2}ms;
      opacity: 1;
    `}

  img {
    object-fit: cover;
  }
`;

const Phone = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 3px solid ${(props) => props.theme.colors.brown};
  border-radius: 32px;
  overflow: hidden;
  z-index: 100;
`;

const ImageWrapper = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  animation: none;
`;

export default withMedia(OurSolution)({
  query: `(max-width: ${theme.breakpoints.max_sm}px)`,
});
