import styled from 'styled-components';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import ReactMarkdown from 'react-markdown';

import theme from '@/utils/styles-variables';
import withMedia from '@/utils/withMedia';
import getImgSrcWorkaround from '@/utils/images';
import { Col, Row } from '@/components/flexboxgrid';

const STEPS = [
  '/assets/icons/homepage/how-it-works/step1.svg',
  '/assets/icons/homepage/how-it-works/step2.svg',
  '/assets/icons/homepage/how-it-works/step3.svg',
  '/assets/icons/homepage/how-it-works/step4.svg',
];

function Step({ step, index, matches }) {
  const { ref, inView: animate } = useInView({
    rootMargin: '20px',
    threshold: 0.5,
    triggerOnce: true,
  });

  const { description, image, title } = step;

  return matches.mobile ? (
    <MobileContent data-testid="step">
      <img className={`svg svg${index + 1}`} src={STEPS[index]} alt="number image" loading="lazy" />
      <ImageWrapper>
        <img
          className={`image${index + 1}`}
          src={getImgSrcWorkaround(image.data.attributes.url)}
          alt={image.data.attributes.alternativeText}
          loading="lazy"
        />
      </ImageWrapper>
      <InfoCol>
        <Title>{title}</Title>
        <Label>{description}</Label>
      </InfoCol>
    </MobileContent>
  ) : (
    <CustomRow ref={matches.desktop ? ref : undefined} data-testid="step">
      <InfoWrapper className={cn(`info${index + 1}`, { animate })}>
        <img
          className={`svg svg${index + 1}`}
          src={STEPS[index]}
          alt="number image"
          loading="lazy"
        />
        <InfoCol>
          <Title>{title}</Title>
          <Label>{description}</Label>
        </InfoCol>
      </InfoWrapper>
      <WrapperCol>
        <ImageWrapper className={cn({ animate })}>
          <img
            className={`image${index + 1}`}
            src={getImgSrcWorkaround(image.data.attributes.url)}
            alt={image.data.attributes.alternativeText}
            loading="lazy"
          />
        </ImageWrapper>
      </WrapperCol>
    </CustomRow>
  );
}

const CustomRow = styled(Row)`
  padding-bottom: 27px;
  justify-content: space-evenly;

  .info1 {
    margin-top: -50px;
  }

  .info3 {
    margin-top: -25px;
  }

  &:nth-child(2n + 1) {
    flex-direction: row-reverse;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 59px;
    width: 570px;
    margin: 0 auto;

    .info1 {
      margin-top: 0;
    }

    .info3 {
      margin-top: 0;
    }
  }
`;

const InfoWrapper = styled(Col)`
  position: relative;
  display: flex;
  align-items: center;

  .svg {
    width: 100px;
    margin-right: 25px;
  }

  .svg4 {
    width: 118px;
    margin-right: 25px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    align-items: flex-start;

    .svg {
      width: 70px;
    }

    .svg4 {
      width: 80px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    opacity: 0;
    transform: translateY(40px);
    &.animate {
      opacity: 1;
      transform: translateY(0);
      transition:
        opacity 800ms 600ms ease-out,
        transform 800ms 600ms ease-out;
    }
  }
`;

const WrapperCol = styled(Col)`
  align-self: center;
`;

const ImageWrapper = styled.div`
  .image1 {
    width: 411px;
  }

  .image2 {
    width: 335px;
  }

  .image3 {
    width: 377px;
  }

  .image4 {
    width: 351px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    .image1 {
      width: 257px;
    }

    .image2 {
      width: 210px;
    }

    .image3 {
      width: 244px;
    }

    .image4 {
      width: 227px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    .image1 {
      width: 218px;
      margin-left: -20px;
    }

    .image2 {
      width: 186px;
    }

    .image3 {
      width: 188px;
    }

    .image4 {
      width: 193px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    opacity: 0;
    transform: translateY(40px);
    &.animate {
      opacity: 1;
      transform: translateY(0);
      transition:
        opacity 800ms 200ms ease-out,
        transform 800ms 200ms ease-out;
    }
  }
`;

const InfoCol = styled(Col)`
  font-family: ${(props) => props.theme.fonts.grosa};
  color: ${(props) => props.theme.colors.brown};
  text-align: left;
  padding-top: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 240px;
    padding-top: 27px;
    padding-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 22px;
    max-width: max-content;
  }
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  color: ${(props) => props.theme.colors.brown};
  padding-bottom: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 22px;
    line-height: 28px;
    padding-bottom: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 13px;
  }
`;

const Label = styled(ReactMarkdown)`
  font-size: 18px;
  line-height: 30px;
  max-width: 292px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: max-content;
  }

  a {
    color: ${(props) => props.theme.colors.purple};
    text-decoration: underline;
  }
`;

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 60px;

  .svg {
    width: 70px;
    padding-bottom: 6px;
  }

  .svg4 {
    width: 80px;
    padding-bottom: 0;
  }
`;

export default withMedia(Step)({
  queries: {
    desktop: `(min-width: ${theme.breakpoints.min_xl}px)`,
    mobile: `(max-width: ${theme.breakpoints.max_sm}px)`,
  },
  defaultMatches: {
    desktop: false,
    mobile: false,
  },
});
