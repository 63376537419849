import { memo } from 'react';
import styled, { css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import ReactMarkdown from 'react-markdown';

const Wrapper = styled.span`
  font-family: ${(props) => props.theme.fonts.grosa};
  font-size: 85px;
  color: ${(props) => props.theme.colors.brown};
  line-height: 90px;
  letter-spacing: -0.03em;
  font-weight: 300;
  white-space: pre-line;

  ${({ $centered }) =>
    $centered &&
    css`
      text-align: center;
    `}

  ${({ $smaller }) =>
    $smaller &&
    css`
      font-size: 40px;
      line-height: 55px;
    `}

  ${({ $medium }) =>
    $medium &&
    css`
      font-size: 70px;
      line-height: 85px;

      @media (max-width: ${(props) => props.theme.breakpoints.min_xl}px) {
        font-size: 45px;
        line-height: 55px;
        letter-spacing: -0.03em;
      }
    `}

  i {
    position: relative;
    display: inline-block;
    font-family: ${(props) => props.theme.fonts.nantes_book};
    font-weight: 500;
    font-style: italic;
    white-space: nowrap;
    padding-bottom: 0;
  }

  svg {
    position: absolute;
    width: 100%;
    height: 10px;
    left: 0;
    bottom: -10px;
    padding: 0 0.2em;
    ${({ $variant }) =>
      $variant &&
      css`
        padding: 0;
        height: 6px;
        bottom: -4px;
      `}

    stroke-dasharray: 1100;
    stroke-dashoffset: 1100;
    transition: stroke-dashoffset 1.17s ease-out;
  }

  &.animate svg {
    stroke-dashoffset: 0;
  }

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};

      svg {
        stroke: ${$color};
      }
    `};

  ${({ $stroke }) =>
    $stroke &&
    css`
      svg {
        stroke: ${$stroke};
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -0.02em;
    ${({ $variant, $medium }) =>
      ($variant || $medium) &&
      css`
        font-size: 40px;
        line-height: 55px;
      `}
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 40px;
    line-height: 55px;
    padding-top: 24px;

    ${({ $header }) =>
      $header &&
      css`
        line-height: 48px;
      `}
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    ${({ $variant }) =>
      $variant &&
      css`
        font-size: 35px;
        line-height: 40px;
      `}
  }
`;

const UnderLineTitleContent = ({ title }) => (
  <ReactMarkdown
    allowedElements={['strong', 'em', 'a']}
    unwrapDisallowed
    components={{
      em: (props) => {
        return (
          <i>
            {props.children}
            <svg
              viewBox="0 0 398 11"
              width="398"
              height="11"
              fill="none"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#FFA489"
              strokeWidth="4"
              strokeLinecap="round"
            >
              <path d="M3.503 2.168c119.214 0 238.44-.379 357.653 0 10.898.035 43.547 1.18 32.648 1.18-58.384 0-116.705.285-175.078.918-67.405.732-269.466-.37-202.203 2.623 88.1 3.92 177.431 1.18 265.724 1.18" />
            </svg>
          </i>
        );
      },
    }}
  >
    {title}
  </ReactMarkdown>
);

const UnderLineTitleContentMemoized = memo(UnderLineTitleContent);

const UnderLineTitle = ({
  title,
  centered,
  smaller,
  medium,
  variant,
  header,
  color,
  stroke,
  noLine,
  ...rest
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
    delay: 600,
    initialInView: false,
  });

  return (
    <Wrapper
      ref={ref}
      className={!noLine && inView ? 'animate' : ''}
      $centered={centered}
      $smaller={smaller}
      $medium={medium}
      $variant={variant}
      $header={header}
      $color={color}
      $stroke={stroke}
      {...rest}
    >
      <UnderLineTitleContentMemoized title={title} />
    </Wrapper>
  );
};

export default UnderLineTitle;
