import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';

import withMedia from '@/utils/withMedia';
import theme from '@/utils/styles-variables';
import { Container, Row, Col } from '@/components/flexboxgrid';
import UnderLineTitle from '@/components/underline-title';
import Step from './step';

import Shape from '../../../public/assets/icons/homepage/how-it-works/shapes-purple.svg';

const HowItWorks = ({ content, matches: isMobile }) => {
  const { title, steps } = content;
  return (
    <Wrapper>
      <Container>
        <BigWrapper>
          <PurpleShape />
          <TitleRow>
            <Col lg={12} md={12} xs={12}>
              <UnderLineTitle title={title} smaller variant />
            </Col>
          </TitleRow>
          {steps.map((step, index) => (
            <Step key={index} step={step} index={index} />
          ))}
        </BigWrapper>
      </Container>
      <BgWrapper>
        <Image
          className="bg-image"
          src={
            isMobile
              ? '/assets/images/homepage/how-it-works/mobile-bg-works.png'
              : '/assets/images/homepage/how-it-works/bg-works.png'
          }
          alt=""
          fill
          sizes="100vw"
        />
      </BgWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
  padding: 109px 0 103px 0;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 39px;
    padding-bottom: 52px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 39px;
    padding-bottom: 33px;
  }
`;

const BgWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  z-index: -1;
  min-height: 2250px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    min-height: 1727px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    min-height: 2035px;
  }

  img {
    object-fit: cover;
  }
`;

const BigWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    align-items: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    align-items: flex-start;
  }
`;

const PurpleShape = styled(Shape)`
  position: absolute;
  right: -270px;
  top: 435px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    top: 390px;
    right: -300px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    right: -320px;
    top: 450px;
  }
`;

const TitleRow = styled(Row)`
  padding-bottom: 116px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 73px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 46px;
    align-self: center;
  }
`;

export default withMedia(HowItWorks)({
  query: `(max-width: ${theme.breakpoints.max_sm}px)`,
});
