import React, { useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';

import { Container } from '@/components/flexboxgrid';
import VideoPlayerModal, { PlayButton } from '@/components/core/VideoPlayerModal';
import UnderLineTitle from '@/components/underline-title';

import ShapeSVG from '../../../public/assets/icons/homepage/our-clients/shape.svg';

import getImgSrcWorkaround from '@/utils/images';

export default function OurClients({ content }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    title,
    ourClientsCustomerName,
    ourClientsCustomerRole,
    ourClientsImage,
    ourClientsSubtitle,
    ourClientsLabel,
    ourClientsCustomerVideo,
  } = content;
  const video = ourClientsCustomerVideo?.data;

  return (
    <Wrapper>
      {video && (
        <VideoPlayerModal
          closeModal={() => setModalIsOpen(false)}
          modalIsOpen={modalIsOpen}
          videoURL={getImgSrcWorkaround(video.attributes.url)}
        />
      )}
      <StyledContainer>
        <Shape />
        <Title>
          <UnderLineTitle title={title} smaller variant centered />
        </Title>
        <Line>
          <ImageWrapper>
            <Image
              alt={ourClientsImage.data.attributes.alternativeText}
              src={getImgSrcWorkaround(ourClientsImage.data.attributes.url)}
              fill
              sizes="(max-width: 768px) 100vw, 50vw"
              data-testid="image"
            />
            <CustomerInfo>
              <CustomerName>{ourClientsCustomerName}</CustomerName>
              <CustomerRole>{ourClientsCustomerRole}</CustomerRole>
            </CustomerInfo>
            {video && <PlayButton onClick={() => setModalIsOpen(true)} />}
          </ImageWrapper>

          <Content>
            <Subtitle>{ourClientsSubtitle}</Subtitle>
            <Label>{ourClientsLabel}</Label>
          </Content>
        </Line>
      </StyledContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 126px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 65px;
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  max-width: 1130px;
`;

const Shape = styled(ShapeSVG)`
  position: absolute;
  width: 562px;
  height: 536px;
  left: -341px;
  top: 100px;
  z-index: -1;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 331px;
    height: 316px;
    left: -154px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 352px;
    height: 336px;
    top: 20px;
    left: -154px;
  }
`;

const Title = styled.div`
  margin: 0 auto 110px;
  max-width: 384px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 61px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 40px;
  }
`;

const Line = styled.div`
  display: flex;
  gap: 103px;
  flex-direction: row;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 57px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column-reverse;
    gap: 49px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 552px;
  height: 397px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 50%;
    height: 234px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    height: 201px;
    margin-bottom: 71px;
  }

  img {
    object-fit: cover;
    object-position: center right;
  }
`;

const CustomerInfo = styled.div`
  font-size: 18px;
  line-height: 25px;
  position: absolute;
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    bottom: 32px;
    left: 32px;
    color: #fff;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    bottom: 13px;
    left: 16px;
    color: #fff;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    top: 222px;
    left: 0;
    color: ${(props) => props.theme.colors.brown};
  }
`;

const CustomerName = styled.div`
  font-weight: 500;
  margin-bottom: 2px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    opacity: 0.9;
  }
`;

const CustomerRole = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    opacity: 0.7;
  }
`;

const Content = styled.div`
  max-width: 384px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.brown};
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 50%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
    width: 100%;
  }
`;

const Subtitle = styled.div`
  font-size: 33px;
  line-height: 44px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 23px;
    line-height: 38px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 22px;
    line-height: 30px;
  }
`;

const Label = styled.div`
  font-size: 18px;
  line-height: 30px;
  margin-top: 22px;
  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    max-width: 281px;
  }
`;
