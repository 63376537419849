import styled from 'styled-components';

import PlayIcon from '../../../public/assets/icons/play.svg';

const PlayButton = ({ onClick }) => (
  <Button onClick={onClick} data-testid="play-video">
    <PlayIcon />
  </Button>
);

const Button = styled.button`
  position: absolute;
  width: 82px;
  height: 82px;
  top: 50%;
  left: 50%;
  transform: scale(1) translate(-50%, -50%);
  transition: all 0.3s ease;
  z-index: 100;
  cursor: pointer;
  &:hover {
    transform: scale(1.1) translate(-50%, -50%);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      transform: scale(0.5);
    }
  }
`;

export default PlayButton;
