import Image from 'next/image';
import styled from 'styled-components';
import { rgba } from 'polished';

import { Container, Row, Col } from '@/components/flexboxgrid';

import withMedia from '@/utils/withMedia';
import theme from '@/utils/styles-variables';
import getImgSrcWorkaround from '@/utils/images';

const TestimonialWrapper = styled.div`
  background-color: ${(props) => rgba(props.theme.colors.light_peach, 0.2)};
  padding: 60px 0;
`;

const FirstCol = styled(Col)`
  order: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    order: 2;
    width: 100%;
  }
`;

const SecondCol = styled(Col)`
  display: flex;
  align-items: center;
  order: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    order: 1;
    width: 100%;
  }
`;

const SentenceWrapper = styled.div`
  max-width: 510px;
  color: ${(props) => props.theme.colors.brown};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 325px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 300px;
    margin: auto;
  }
`;

const Sentence = styled.div`
  position: relative;
  font-size: 25px;
  line-height: 32px;
  margin-bottom: 35px;
  font-weight: 400;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    font-size: 23px;
    line-height: 32px;
    margin-bottom: 22px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 16px;
  }
`;

const Quote = styled.div`
  position: absolute;
  top: -65px;
  left: -45px;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    top: -30px;
  }
`;

const Author = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.brown};
  opacity: 0.8;
`;

const Role = styled.div`
  font-size: 20px;
  line-height: 30px;
  color: ${(props) => props.theme.colors.brown};
  opacity: 0.8;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 60px;
  }
`;

const PictureWrapper = styled.div`
  transform: translateX(-270px);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    transform: translateX(-190px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    transform: unset;
    text-align: center;
  }
`;

const Testimonial = ({ content, matches: size }) => {
  if (!content || !content.testimonial || !content.image) return null;

  const { testimonial, image, author, role } = content;

  let pictureWidth = 720;
  let pictureHeight = 585;

  if (size.mobile) {
    pictureWidth = 354;
    pictureHeight = 301;
  } else if (size.tablet) {
    pictureWidth = 508;
    pictureHeight = 432;
  }

  return (
    <TestimonialWrapper>
      <Container>
        <Row>
          <FirstCol sm={12} md={6}>
            <PictureWrapper>
              <Image
                src={getImgSrcWorkaround(image.data.attributes.url)}
                width={pictureWidth}
                height={pictureHeight}
                alt={author}
                data-testid="testimonial-image"
              />
            </PictureWrapper>
          </FirstCol>
          <SecondCol sm={12} md={6}>
            <SentenceWrapper>
              <Sentence data-testid="testimonial-quote">
                <Quote>
                  <Image src={'/assets/icons/quote.svg'} width={123} height={94} alt="quote" />
                </Quote>

                {testimonial}
              </Sentence>
              <Author data-testid="testimonial-author">{author}</Author>
              <Role data-testid="testimonial-role">{role}</Role>
            </SentenceWrapper>
          </SecondCol>
        </Row>
      </Container>
    </TestimonialWrapper>
  );
};

export default withMedia(Testimonial)({
  queries: {
    tablet: `(max-width: ${theme.breakpoints.max_md}px)`,
    mobile: `(max-width: ${theme.breakpoints.max_sm}px)`,
  },
  defaultMatches: {
    tablet: false,
    mobile: false,
  },
});
