import Image from 'next/image';
import styled, { css } from 'styled-components';

const IconContainer = ({ src, alt, homepage, className, active }) => {
  return (
    <StyledContainer className={className} homepage={homepage} active={active}>
      <Image src={src} alt={alt} fill sizes="300px" />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  height: 309px;
  width: 193px;
  bottom: -55px;
  right: -88px;
  transition: all 500ms ease-in-out;
  opacity: 0;
  z-index: 100;
  transform: translateX(20px);

  ${(props) =>
    props.homepage &&
    css`
      bottom: -52px;
      left: calc(50% + 45px);
      right: unset;
      height: 327px;
      width: 205px;
    `}

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      transform: translateX(0);
      transition-delay: 250ms;
    `}
  

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    bottom: -40px;
    right: -90px;
    width: 175px;
    height: 245px;

    ${(props) =>
      props.homepage &&
      css`
        bottom: -45px;
        width: 175px;
        height: 230px;
        left: calc(50% + 15px);
      `}
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 150px;
    height: 250px;
    bottom: -40px;
    left: 55%;

    ${(props) =>
      props.homepage &&
      css`
        width: 75px;
        height: 120px;
        bottom: 25px;
        left: 54%;
      `}
  }

  img {
    object-fit: contain;
  }
`;

export default IconContainer;
