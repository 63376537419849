// eslint-disable-next-line no-unused-vars
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/nextjs';

const defaultOptions = {
  renderer: 'svg',
  loop: false,
  autoplay: false,
};

/**
 * @param {Object | String} data Either animationData or path to file
 * @param {AnimationConfigWithPath | AnimationConfigWithData} options Options for creating an animation item.
 * @returns {Array<AnimationItem, MutableRefObject>} [ item , ref ]
 */
function useLottie(data, options) {
  const ref = useRef();
  const [lottie, setLottie] = useState(null);
  const [item, setItem] = useState();

  useEffect(() => {
    import('lottie-web')
      .then((Lottie) => setLottie(Lottie.default))
      .catch((error) => {
        console.warn('Failed to load lottie', error);
        Sentry.captureException(error);
      });
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const newItem = lottie.loadAnimation({
        ...defaultOptions,
        ...options,
        [typeof data === 'string' ? 'path' : 'animationData']: data,
        container: ref.current,
      });

      setItem(newItem);

      return () => newItem.destroy();
    }
  }, [data, lottie, options, ref]);

  return [item, ref];
}

export default useLottie;
