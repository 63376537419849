import styled from 'styled-components';
import Carousel from 'nuka-carousel';

import FadeCard from '@/components/fade-card';
import { Container } from '@/components/flexboxgrid';
import UnderLineTitle from '@/components/underline-title';

import withMedia from '@/utils/withMedia';
import theme from '@/utils/styles-variables';

import DesktopConditionsWeCover from './desktop';

const TitleContainer = styled(Container)`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    margin-top: -30px;
  }
`;

const MobileWrapper = styled.div`
  position: relative;
  height: 396px;
  margin-bottom: 40px;

  & .slider-list {
    -webkit-tap-highlight-color: transparent;
  }

  & .slider-slide {
    outline: none;
  }

  & .dots-container {
    top: 40px !important;
  }

  & .slider-control-bottomcenter .paging-item:not(.active) button {
    opacity: 0.2 !important;
  }
`;

const MobileCard = styled(FadeCard)`
  height: 396px;
`;

const ConditionsWeCover = ({ content, matches }) => {
  const { title, item: list } = content;

  return (
    <Container data-testid="conditions-we-cover">
      <TitleContainer>
        <UnderLineTitle title={title} centered smaller variant />
      </TitleContainer>
      {matches.tablet || matches.mobile ? (
        <MobileWrapper data-testid="conditions-mobile-wrapper">
          <Carousel
            width="100%"
            slidesToShow={matches.mobile ? 1 : 2}
            dragging
            cellSpacing={32}
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
            defaultControlsConfig={{
              pagingDotsContainerClassName: 'dots-container',
              pagingDotsStyle: {
                backgroundColor: theme.colors.brown,
                fill: 'transparent',
                borderRadius: '50%',
                height: '9px',
                width: '9px',
                margin: '0 3px',
              },
            }}
          >
            {list.map((condition) => (
              <MobileCard
                key={condition.id}
                title={condition.title}
                description={condition.description}
                image={condition.image}
              />
            ))}
          </Carousel>
        </MobileWrapper>
      ) : (
        <DesktopConditionsWeCover list={list} />
      )}
    </Container>
  );
};

export default withMedia(ConditionsWeCover)({
  queries: {
    tablet: `(min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px)`,
    mobile: `(max-width: ${theme.breakpoints.max_sm}px)`,
  },
  defaultMatches: {
    tablet: false,
    mobile: false,
  },
});
